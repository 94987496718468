<template>
  <div class="login-container">
    <div class="login-block">
      <div class="login-block-container">
        <div class="login-logo">
          <el-image
            fit="fill"
            :src="require('../../assets/image/logo.png')"
          ></el-image>
        </div>
        <div class="login-container-out">
          <div class="login-form-title">用户登录</div>
          <div class="login-form-line" />
          <div class="login-form-right">
            <el-form
              class="login-form"
              label-width="120px"
              label-position="right"
              :model="loginForm"
            >
              <el-form-item label="用户名：" required>
                <el-input
                  v-model="loginForm.name"
                  placeholder="请输入账号"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码：" required>
                <el-input
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码：" required>
                <div class="identify">
                  <el-input
                    style="width: 182px"
                    v-model="loginForm.identifyCodes"
                    placeholder="请输入验证码"
                  ></el-input>
                  <div class="identify-code" @click="refreshCode">
                    <identify :identifyCode="identifyCode" />
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="谷歌验证码：" required>
                <el-input
                  v-model="loginForm.code"
                  placeholder="请输入谷歌验证码"
                ></el-input>
              </el-form-item>
              
              <div class="form-loginbtn">
                <el-button type="success" @click="login" style="width: 250px"
                  >登录</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Identify from "@/components/identify";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  components: { Identify },
  data() {
    return {
      loginForm: {
        name: "",
        password: "",
        identifyCodes: "",
        code:""
      },
      identifyCode: "",
      identifyCodes: "1234567890",
    };
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    login() {
      if (this.loginForm.identifyCodes !== this.identifyCode) {
        this.$message({
          showClose: true,
          message: "登陆失败，验证码错误",
          type: "error",
        });
        this.refreshCode();
        return;
      }
      axios
        .post("https://api.q0jef6.com/index/login", {
          account: this.loginForm.name,
          password: this.loginForm.password,
          code:this.loginForm.code
        })
        .then((res) => {
          if (res.data.code === 200) {
            localStorage.setItem("login", "success");
            this.$router.push("/dashboard");
          } else {
            this.$message({
              showClose: true,
              message: "登陆失败，" + res.data.msg,
              type: "error",
            });
          }
        });
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  min-height: 100%;
  width: 100%;
  min-width: 1280px;
  height: 100%;
  background-image: url("../../assets/image/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  position: relative;
}
.login-block {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.login-block-container {
  width: 1400px;
}
.login-logo {
  display: inline-block;
  vertical-align: middle;
  padding-left: 100px;
}
.login-container-out {
  display: inline-block;
  vertical-align: middle;
  margin-left: 300px;
  background-color: #faf7e3;
  width: 450px;
  border: 5px solid #e78e07;
  border-radius: 5px;
}
.login-form-title {
  font-size: 30px;
  color: #132555;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.login-form-line {
  height: 3px;
  background-color: #f3b058;
  border-radius: 3px;
  width: 400px;
  margin: 0 auto;
}
.login-form-right {
  margin-top: 50px;
}
.login-form {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.form-loginbtn {
  width: 100%;
  margin: 70px auto 70px;
  text-align: center;
}
.login-container .el-input {
  display: inline-block;
  height: 47px;
  width: 300px;
}
.identify {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
.identify-code {
  display: inline-block;
  height: 40px;
  width: 112px;
}

::v-deep .el-form-item__label {
  font-size: 20px;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
}
</style>
