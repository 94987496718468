<template>
  <div class="dashboard-container">
    <div class="home-container">
      <div class="top-container">
        <div class="top-container-out">
          <div class="top-logo">
            <el-image
              fit="fill"
              :src="require('../../assets/image/top-logo.png')"
              style="width: 200px"
            ></el-image>
          </div>
          <div class="top-search">
            <el-menu
              :default-active="active"
              class="el-menu-demo"
              mode="horizontal"
              style="background-color: rgba(0, 0, 0, 0)"
            >
              <el-menu-item
                index="search"
                @click="
                  active = 'search';
                  searchValue = 'single-search';
                "
                >查询</el-menu-item
              >
              <el-menu-item
                index="add"
                @click="
                  active = 'add';
                  addValue = 'single-add';
                "
                >新增</el-menu-item
              >
            </el-menu>
            <div class="top-search-input">
              <div class="input-check" v-show="active === 'search'">
                <el-select
                  v-model="searchValue"
                  placeholder="请选择"
                  class="input-check-select"
                >
                  <el-option
                    v-for="item in searchOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-show="searchValue === 'single-search'"
                  class="input-with-select-check"
                  v-model="singleSearchInput"
                  placeholder="请输入手机号(单个)"
                />
                <div
                  class="multiple-input"
                  v-show="searchValue === 'multiple-search'"
                >
                  <el-input
                    class="input-with-select-check"
                    type="textarea"
                    v-model="multipleSearchInput"
                    placeholder="请输入手机号(批量)"
                    :autosize="{ minRows: 4, maxRows: 4 }"
                  />
                  <div class="multiple-input-info">
                    当前输入<span>{{ multipleData.multipleList.length }}</span
                    >条数据，其中<span>{{ multipleData.multipleSet.size }}</span
                    >条有效
                  </div>
                </div>

                <el-upload
                  action="#"
                  :on-change="fileSelectSuccess"
                  :on-remove="handleRemove"
                  v-show="searchValue === 'file-search'"
                  :auto-upload="false"
                  :limit="1"
                  accept=".txt"
                >
                  <el-input
                    disabled
                    class="input-with-select-check"
                    v-model="fileData.value"
                    placeholder="点击选择文件"
                  />
                </el-upload>

                <el-button class="search-btn" @click="searchList"
                  >查询</el-button
                >
              </div>
              <div class="input-add" v-show="active === 'add'">
                <el-select
                  v-model="addValue"
                  placeholder="请选择"
                  class="input-check-select"
                >
                  <el-option
                    v-for="item in addOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-show="addValue === 'single-add'"
                  class="add-input"
                  v-model="singleAddInput"
                  placeholder="请输入手机号(单个)"
                />
                <div class="multiple-add" v-show="addValue === 'multiple-add'">
                  <el-input
                    class="add-input"
                    type="textarea"
                    v-model="multipleAddInput"
                    placeholder="请输入手机号(批量)"
                    :autosize="{ minRows: 4, maxRows: 4 }"
                  />
                  <div class="multiple-add-info">
                    当前输入<span>{{
                      multipleAddData.multipleList.length
                    }}</span
                    >条数据，其中<span>{{
                      multipleAddData.multipleSet.size
                    }}</span
                    >条有效
                  </div>
                </div>
                <el-upload
                  action="#"
                  :on-change="fileSelectSuccess"
                  :on-remove="handleRemove"
                  v-show="addValue === 'file-add'"
                  :auto-upload="false"
                  :limit="1"
                  accept=".txt"
                >
                  <el-input
                    disabled
                    class="add-input"
                    v-model="fileData.value"
                    placeholder="点击选择文件"
                  />
                </el-upload>
                <el-input
                  class="add-audit"
                  v-model="auditInput"
                  placeholder="请输入审核员"
                />
                <el-button
                  class="add-btn"
                  @click="addList"
                  v-loading="addLoading"
                  >添加</el-button
                >
              </div>
            </div>
            <el-dropdown
              trigger="click"
              style="position: absolute; left: 950px; top: 0"
              @command="logout"
            >
              <el-avatar
                class="el-dropdown-link"
                :size="100"
                :src="require('../../assets/image/avatar.e3eec7b5.png')"
                fit="contain"
              ></el-avatar>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="logout">登出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="table-container">
        <div class="table-top" v-show="!detailFlag">
          <span>当前数据库中一共</span
          ><span style="color: rgb(234, 115, 13); padding: 0 10px">{{
            total
          }}</span
          ><span>条数据</span>
        </div>
        <div class="table-top" v-show="detailFlag">
          <span>本次共添加</span
          ><span style="color: rgb(234, 115, 13); padding: 0 10px">{{
            addTotal
          }}</span
          ><span>条数据，其中</span
          ><span style="color: rgb(234, 115, 13); padding: 0 10px">{{
            newSize
          }}</span
          ><span>条数据添加成功，有</span>
          <span style="color: rgb(234, 115, 13); padding: 0 10px">{{
            existSize
          }}</span
          ><span>条数据已存在数据库中</span>
          >
        </div>
        <div class="select-table-out">
          <el-table
            :data="list"
            border
            style="width: 100%"
            v-loading="searchLoading"
          >
            <el-table-column prop="mobile" label="手机号" />
            <el-table-column
              align="center"
              prop="auditor"
              label="审核员"
              width="250"
            />
            <el-table-column
              align="center"
              prop="createtime"
              label="入库时间"
              width="250"
            >
              <template slot-scope="scope">
                <span
                  :style="{
                    color:
                      scope.row.createtime === '添加成功' ? 'green' : 'black',
                  }"
                  >{{ scope.row.createtime }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="list-pagination-container">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
  mounted() {
    this.getAllData();
  },
  data() {
    return {
      active: "search",
      searchValue: "single-search",
      searchOption: [
        {
          value: "single-search",
          label: "单个查询",
        },
        {
          value: "multiple-search",
          label: "批量查询",
        },
        {
          value: "file-search",
          label: "按文件查询",
        },
      ],
      singleSearchInput: "",
      multipleSearchInput: "",
      multipleAddInput: "",
      fileSearchInput: "",
      fileInput: "",
      addValue: "multiple-add",
      addOption: [
        {
          value: "single-add",
          label: "单个添加",
        },
        {
          value: "multiple-add",
          label: "批量添加",
        },
        {
          value: "file-add",
          label: "按文件添加",
        },
      ],
      auditInput: "",
      singleAddInput: "",

      list: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      searchLoading: false,
      addLoading: false,

      detailFlag: false,
      addTotal: 0,
      existSize: 0,
      newSize: 0,
    };
  },
  methods: {
    getAllData() {
      this.searchLoading = true;
      axios
        .post("https://api.q0jef6.com/index/page", {
          pageCurrent: this.currentPage,
          pageSize: this.pageSize,
        })
        .then((res) => {
          const result = res.data.data;
          this.total = result.total;
          this.list = result.list;
          this.searchLoading = false;
          this.detailFlag = false;
        })
        .catch(() => {
          this.searchLoading = false;
          this.detailFlag = false;
        });
    },
    getDataByPhoneList(phoneList) {
      this.searchLoading = true;
      axios
        .post("https://api.q0jef6.com/index/search", {
          ids: phoneList,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.list = res.data.data;
          this.searchLoading = false;
          this.detailFlag = false;
        })
        .catch(() => {
          this.searchLoading = false;
          this.detailFlag = false;
        });
    },
    addData(list, auditor) {
      this.addLoading = true;
      axios
        .post("https://api.q0jef6.com/index/input", {
          ids: list,
          auditor,
        })
        .then((res) => {
          console.log(res);
          this.addTotal =
            res.data.data.existList.length + res.data.data.newList.length;
          this.newSize = res.data.data.newList.length;
          this.existSize = res.data.data.existList.length;

          if (this.addTotal < 500) {
            this.list = [
              ...res.data.data.newList.map((item) => {
                item.createtime = "添加成功";
                return item;
              }),
              ...res.data.data.existList,
            ];
          }
          this.addLoading = false;
          this.detailFlag = true;
        })
        .catch(() => {
          this.addLoading = false;
          this.detailFlag = true;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getAllData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAllData();
    },
    searchList() {
      if (this.searchValue === "single-search") {
        if (this.singleSearchInput !== null && this.singleSearchInput !== "") {
          this.getDataByPhoneList([this.singleSearchInput]);
        } else {
          this.getAllData();
        }
      } else if (this.searchValue === "multiple-search") {
        if (
          this.multipleSearchInput !== null &&
          this.multipleSearchInput !== ""
        ) {
          this.getDataByPhoneList(this.multipleData.multipleList);
        } else {
          this.getAllData();
        }
      } else if (this.searchValue === "file-search") {
        if (this.fileData.fileList.length !== 0) {
          this.getDataByPhoneList(this.fileData.fileList);
        } else {
          this.getAllData();
        }
      }
    },
    addList() {
      if (this.auditInput === null || this.auditInput === "") {
        this.$notify.warning({
          title: "提示",
          message: "审核员必填",
        });
        return;
      }
      if (this.addValue === "single-add") {
        if (this.singleAddInput !== "" && this.singleAddInput !== null) {
          this.addData([this.singleAddInput], this.auditInput);
        }
      } else if (this.addValue === "multiple-add") {
        this.addData(this.multipleAddData.multipleList, this.auditInput);
      } else if (this.addValue === "file-add") {
        this.addData(this.fileData.fileList, this.auditInput);
      }
    },
    fileSelectSuccess(file) {
      const reader = new FileReader();
      const that = this;
      reader.onload = () => {
        that.fileInput = reader.result;
      };
      reader.readAsText(file.raw);
    },
    handleRemove() {
      this.fileInput = "";
    },
    logout(command) {
      if (command === "logout") {
        localStorage.removeItem("login");
        this.$router.push("/");
      }
    },
  },
  computed: {
    multipleData() {
      const dataList = this.multipleSearchInput.split("\n").filter(Boolean);
      const dataSet = new Set(dataList);
      return {
        multipleList: dataList,
        multipleSet: dataSet,
      };
    },
    multipleAddData() {
      const dataList = this.multipleAddInput.split("\n").filter(Boolean);
      const dataSet = new Set(dataList);
      return {
        multipleList: dataList,
        multipleSet: dataSet,
      };
    },
    fileData() {
      const dataList = this.fileInput.split(/\r\n|[\r\n]/).filter(Boolean);
      console.log(dataList);
      const dataSet = new Set(dataList);
      return {
        fileList: dataList,
        fileSet: dataSet,
        value: dataList.toString(),
      };
    },
  },
};
</script>

<style scoped lang="scss">
// top
.home-container {
  overflow: hidden;
}
.top-container {
  width: 100%;
  background-image: url("../../assets/image/top.png");
  background-repeat: repeat-x;
  background-position: 50% center;
}
.top-container-out {
  width: 1400px;
  height: 210px;
  margin: 0 auto;
}
.top-logo {
  float: left;
}
.top-search {
  float: left;
  margin-left: 50px;
  margin-top: 60px;
  width: 900px;
  position: relative;
}
.top-search-input {
  position: relative;
  width: 900px;
  background-color: transparent;
}
.input-check-select {
  width: 120px;
  float: left;
  vertical-align: top;
}

::v-deep .el-menu-item.is-active {
  background: rgb(152, 45, 3) !important;
  border-bottom-color: transparent !important;
}
::v-deep .el-menu-item {
  width: 150px;
  line-height: 40px !important;
  height: 40px !important;
  text-align: center;
  font-size: 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: rgb(220, 217, 135) !important;
  border-bottom-color: transparent !important;
}

// container
.table-container {
  width: 1400px;
  margin: 20px auto 0;
}
.table-top {
  overflow: hidden;
  display: block;
  height: 30px;
  line-height: 30px;
}
.select-table-out {
  border: 3px solid rgba(0, 0, 0, 0.5);
}
.list-pagination-container {
  background: #fff;
  padding: 16px;
  margin: 0 auto;
}
.input-with-select-check {
  width: 710px;
  height: 40px;
  float: left;
  vertical-align: top;
}
.search-btn,
.add-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: #b74502;
  border: 0;
  color: #fff;
  height: 40px;
  &:focus,
  &:hover,
  &:active {
    background: #b74502;
    color: #fff;
  }
}
.multiple-input {
  position: relative;
  &-info {
    position: absolute;
    top: 90px;
    left: 120px;
    width: 710px;
    background-color: #aaa8a8;
    span {
      letter-spacing: 2px;
      color: #b74502;
    }
  }
}
.multiple-add {
  position: relative;
  &-info {
    position: absolute;
    top: 90px;
    left: 120px;
    width: 510px;
    background-color: #aaa8a8;
    span {
      letter-spacing: 2px;
      color: #b74502;
    }
  }
}

.add-input {
  width: 510px;
  height: 40px;
  float: left;
  vertical-align: top;
}
.add-audit {
  position: absolute;
  top: 0;
  left: 630px;
  width: 200px;
}

::v-deep th {
  background-color: rgb(251, 210, 148) !important;
  color: rgb(136, 48, 48);
}
::v-deep .el-textarea__inner {
  min-height: 40px !important;
}
::v-deep .el-input.is-disabled .el-input__inner {
  cursor: pointer;
}
::v-deep .el-avatar {
  background-color: transparent;
}
</style>
