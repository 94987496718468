import { render, staticRenderFns } from "./identify.vue?vue&type=template&id=aa40611a&"
import script from "./identify.vue?vue&type=script&lang=js&"
export * from "./identify.vue?vue&type=script&lang=js&"
import style0 from "./identify.vue?vue&type=style&index=0&id=aa40611a&prod&lang=scss&"


/* normalize component */
import normalizer from "!C:\\Program Files\\nodejs\\node_global\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports