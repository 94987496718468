import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5be8b2e1&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=5be8b2e1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!C:\\Program Files\\nodejs\\node_global\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be8b2e1",
  null
  
)

export default component.exports