import { render, staticRenderFns } from "./App.vue?vue&type=template&id=268de2d7&"
var script = {}


/* normalize component */
import normalizer from "!C:\\Program Files\\nodejs\\node_global\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports